/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Cookies from 'js-cookie';
import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';

if (document.querySelectorAll('.same-height').length) {
	new ResponsiveAutoHeight('.same-height');
}

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Scroll text block
var scrollTextBlock = document.querySelectorAll('.scroll-text-block .container');
if (scrollTextBlock.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		scrollTextBlock.forEach(el => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: scrollTextBlock,
					start: "center center",
					end: "+=100%",
					scrub: 1,
					pin: true
				}
			});
			var imageColumn = el.querySelector('.panel-column');

			timeline.to(gsap.utils.toArray('.panels', imageColumn), { autoAlpha: 1 }, "<")
			const panels = gsap.utils.toArray('.panel', imageColumn);
			var lastTime = 0;
			var forward = true;

			let lastCompletedPane = "";
			let lastCompletedPanelPos = "";
			let lastCompletedPanelId = 0;

			panels.forEach((panel, i) => {

				function panelLoaded() {
						if (!panels[i].classList.contains("panel-bg")) {
							panels[i].classList.add("panel-bg");
					}

					lastCompletedPane = panels[i];
					lastCompletedPanelPos = panels[i].style.transform;
					lastCompletedPanelId ++;
				}

				function checkPosition() {
					if (lastCompletedPane != "") {
						if (lastCompletedPane.style.transform != lastCompletedPanelPos) {
							if (lastCompletedPane.classList.contains("panel-bg")) {
								lastCompletedPane.classList.remove("panel-bg");
							}
							lastCompletedPanelId--;
							if (lastCompletedPanelId > 0) {
								var id = "panel-" + lastCompletedPanelId;
								; lastCompletedPane = document.getElementById(id);
								lastCompletedPanelPos = lastCompletedPane.style.transform;
							} else {
								lastCompletedPane = "";
								lastCompletedPanelPos = "";
							}
						}
					}
				}

				timeline.fromTo(panel,
				{
					yPercent: i ? 100 : 0
				},
				{
					yPercent: i === panels.length ? 0 : -100,
					duration: i === 0 || i === panels.length ? 0.5 : 1,
					ease: "none", onComplete:panelLoaded, onUpdate:checkPosition
					}, "-=0.5");
				});

				timeline.to({}, { duration: 1 }); // add some extra space at the end so the last panel sits for a bit before unpinning.
		});
	})();
}

// Parallax
var parallaxBlock = document.querySelectorAll('.parallax-block');
if (parallaxBlock.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;
		const parallaxUpArr = gsap.utils.toArray('.parallax-up');
		const parallaxDownArr = gsap.utils.toArray('.parallax-down');

		parallaxUpArr.forEach(el => {
			gsap.to(el, {
				yPercent: -100,
				ease: "none",
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					scrub: true
				},
			});
		});

		parallaxDownArr.forEach(el => {
			gsap.to(el, {
				yPercent: 50,
				ease: "none",
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					scrub: true
				},
			});
		});
	})();
}

// Image slider 
var sliderBlock = document.querySelectorAll('.slider-block .swiper-container');
if (sliderBlock.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(".slider-block .swiper-container", {
			slidesPerView: '1',
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true
			}
		});
	})();
}

// Instagram slider
var instaScrollbar = document.getElementById("swiper-scroll");
var instaSwiper = document.querySelectorAll('.instagram-feed .swiper-container');
if (instaSwiper.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(".instagram-feed .swiper-container", {
			slidesPerView: '1',
			watchOverflow: true,
			scrollbar: {
				el: instaScrollbar,
				hide: false,
				dragSize: 20
			},
		});
	})();
}

// Jump to submitted form
document.querySelectorAll('.contact-form').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// Close dropdown in textblock on backdrop click or escape key
document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("dropdown-label") == false)) {
				toggle(false);
			}
		});
		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Toasts
if (window.toasts) {
	const toastsCookieName = 'toasts',
		toastsCookieOptions = {
			sameSite: 'strict',
			secure: true
		};

	if (window.toasts.length) {
		(async () => {
			const Toasts = (await import('./modules/toasts.js')).default;
			new Toasts(window.toasts, toastsCookieName, toastsCookieOptions);
		})();
	} else {
		// Delete cookie if there are no active toasts
		Cookies.remove(toastsCookieName, toastsCookieOptions);
	}
}

//Filter
const contactFilter = document.querySelector('.contact-filter');
const overview = document.querySelector('.contacts');
const contacts = document.querySelector('.contacts-overview');
if (contactFilter && overview) {

	var activeFilterDropdown = null;

	var onFilterChange = function (parent, label, siblings) {
		var selectedFilterNames = "";
		siblings.forEach(sibling => {
			if (sibling.checked === true) {
				if (selectedFilterNames === "") {
					selectedFilterNames = sibling.nextElementSibling.innerText;
				}
				else {
					selectedFilterNames += ", " + sibling.nextElementSibling.innerText;
				}
			}
		});

		if (selectedFilterNames !== "") {
			label.innerText = selectedFilterNames;
			parent.classList.add('has-values');
		}
		else {
			parent.classList.remove('has-values');
			label.innerText = "Toon alle specialisten";
		}
	};

	var uncheckFilters = function (parent, label, filters) {
		filters.forEach(filter => {
			filter.checked = false;
		});
		onFilterChange(parent, label, filters);
	};

	var filter = function (e) {
		var reset = document.getElementById("filter-reset");
		if (reset && reset.classList.contains("hidden")) {
			reset.classList.remove("hidden");
		}
		// Get the checked inputs from the form
		const data = new URLSearchParams(new FormData(contactFilter));

		// Get new overview
		fetch(window.location.pathname, {
			method: "post",
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {

				// Empty overview
				overview.innerHTML = '';

				// Create DOM
				var parser = new DOMParser();
				var doc = parser.parseFromString(data, 'text/html');
				var hasItems = false;

				// Add items with animation
				doc.querySelectorAll('.column').forEach(function (child, i) {
					child.classList.add("is-hidden");
					overview.appendChild(child);
					setTimeout(function () { child.classList.remove('is-hidden'); }, 40 * i);
					i++;

					if (!child.classList.contains('is-100')) {
						hasItems = true;
					}
				});

				// Make items the same height
				if (hasItems) {
					//new ResponsiveAutoHeight('.same-height');
				}
			});

		// Update the url
		if (history.pushState) {

			if (data.toString() === "") {
				window.history.replaceState({}, '', `${location.pathname}`);
			}
			else {
				window.history.replaceState({}, '', `${location.pathname}?${data}`);
			}
		}
	};

	var contactFilterDropDowns = contactFilter.querySelectorAll('.dropdown-checkbox');
	if (contactFilterDropDowns) {
		contactFilterDropDowns.forEach(el => {
			if (el.tagName.toLowerCase() === "input") {
				// Change label with selected filter names
				var associatedDropdown = el.nextElementSibling;
				var filters = associatedDropdown.querySelectorAll('.filter__input');
				var label = associatedDropdown.querySelector('dt label');
				filters.forEach(filter => {
					filter.addEventListener("change", async function (e) {
						onFilterChange(el, label, filters);
					});
				});
				// Clear selected filters 
				var button = associatedDropdown.querySelector('dt button');
				button.addEventListener("click", async function (e) {
					console.log("dt button click");
					uncheckFilters(el, label, filters);
					filter(e);
					onDropdownChange(activeFilterDropdown);
					e.preventDefault();
				});
			}
		});
	}

	// Get all the inputs
	var inputs = contactFilter.querySelectorAll('input.filter__input');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", async function (e) {
			filter(e);
			e.preventDefault();
		});
	});
}

// Close menu on escape key
const navigationToggler = document.getElementById('menu'), navigation = document.querySelector('.menu'), navigationContainer = document.querySelector('.menu__container');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
		} else {
			focusLock.off(navigationContainer);
		}
	};

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Submenu collapse when opened
var listTogglers = document.querySelectorAll(".list-label")
listTogglers.forEach(el => {
	el.addEventListener('click', () => {
		listTogglers.forEach(nestedEl => {
			if (nestedEl != el && nestedEl.htmlFor != null) {
				document.querySelector("#" + nestedEl.htmlFor).checked = false;
			}
		});
	});
});

var togglers = document.querySelectorAll(".drop-down-toggler")
togglers.forEach(el => {
	el.addEventListener('click', () => {
		togglers.forEach(nestedEl => {
			if (nestedEl != el && nestedEl.htmlFor != null) {
				document.querySelector("#" + nestedEl.htmlFor).checked = false;
			}
		});
	});
});

var overlays = document.querySelectorAll('.overlay');

var overlayBtns = document.querySelectorAll('.overlay-inner-btn').forEach(el => {
	el.addEventListener('click', () => {
		var overlay = el.parentElement.parentElement.parentElement.querySelector('.overlay');
		if (overlay.classList.contains('overlay-hidden')) {
			overlay.classList.remove('overlay-hidden');
		}

		for (var i = 0; i < overlays.length; i++) {
			if (overlay !== overlays[i]) {
				if (!(overlays[i].classList.contains('overlay-hidden'))) {
					overlays[i].classList.add('overlay-hidden');
				}
			}
		}
	});
});

var overlayCloseBtns = document.querySelectorAll('.overlay-close').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add("overlay-hidden");
	});
});

if (overlays.length > 0) {
	document.addEventListener('click', function (e) {
		if (!(e.target.classList.contains("overlay-inner-btn") || e.target.classList.contains("overlay-close") || e.target.classList.contains("overlay"))) {
			for (var i = 0; i < overlays.length; i++) {
				if (!(overlays[i].classList.contains('overlay-hidden'))) {
					overlays[i].classList.add('overlay-hidden');
				}
			}
		}
	});
}
